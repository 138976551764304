import { ApolloLink, DefaultContext, split, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { MockedResponse, ResultFunction } from '@apollo/client/testing';

import { HttpLinkConfig } from '../types';

import { FetchResult, MockLink } from '~/utils/apollo-client';

export const getMockLink = (mocks: MockedResponse[]) => {
  return new MockLink(
    mocks.map(mock => ({
      ...mock,
      // newData allows us to return the same response multiple times, if the query is called multiple times
      newData:
        mock.newData ??
        (typeof mock.result === 'function'
          ? (mock.result as ResultFunction<FetchResult<Record<string, any>>>)
          : () => mock.result as FetchResult<Record<string, any>>),
    })),
    true,
  );
};

export const combineLinks = (linkConfigs: HttpLinkConfig[]): ApolloLink => {
  const [linkConfig, ...rest] = linkConfigs;
  if (linkConfigs.length === 1) {
    return linkConfig.link;
  }
  return split(op => linkConfig.name === op.getContext().clientName, linkConfig.link, combineLinks(rest));
};

export const makeUseQueryWithClientName = (clientName: string): typeof useQuery => (query, options) =>
  useQuery(query, { ...options, context: { clientName } });

export const makeUseLazyQueryWithClientName = (clientName: string): typeof useLazyQuery => (query, options) =>
  useLazyQuery(query, { ...options, context: { clientName } });

export const makeUseMutationWithClientName = (clientName: string): typeof useMutation => (mutation, options) => {
  const optionsWithClientName: DefaultContext = { ...options, context: { ...options?.context, clientName } };
  return useMutation(mutation, optionsWithClientName);
};
